.chart{
    flex: 4;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.10);
    box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.10);
    padding: 10px;
    color: gray;

    .search-container {
        height: 35px;
        display: flex;
        background-color: #fff;
        border: 1px solid #e3e7ed;
        width: 320px;
        border-radius: 6px;
        box-sizing: border-box;
        margin: 0 20px;
      }
      .search-container button {
        border: none;
        background: transparent;
        padding: 0 10px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        cursor: pointer;
      }
      .search-container input {
        border: none;
        background: transparent;
        height: 100%;
        width: 100%;
        color:gray;
        font-weight: 700;
        font-size: 12px;
        outline: none;
      }
      .search-container input::placeholder {
        color: var(--gray);
      }

    .title{
        margin-bottom: 20px;
    }

    .chartGrid{
        stroke: rgb(228, 225, 225);
    }
}