.product-header .order-invoice,
.o-order-invoice-id {
  font-size: 12px;
  width: 180px;
  min-width: 180px;
  word-wrap: break-word;
}
.product-header .order-date,
.o-order-date {
  width: 130px;
  min-width: 130px;
}
.product-header .order-customer,
.o-customer-store {
  width: 100%;
  min-width: 200px;
  max-width: 200px;
}
.product-header .order-driver,
.o-order-driver-name {
  width: 200px;
  min-width: 200px;
}
.product-header .order-status,
.o-order-status {
  width: 150px;
  min-width: 150px;
}
.product-header .order-price,
.o-order-amount {
  width: 150px;
  min-width: 150px;
}
.product-header .order-action,
.o-order-action {
  width: 50px;
  min-width: 50px;
}
.o-customer-store {
  display: flex;
  flex-direction: column;
}
.status-complete {
  color: #38c985;
  background: rgba(56, 201, 133, 0.1);
  padding: 2px 9px;
  border-radius: 6px;
}
.status-cancelled {
  color: #ff4127;
  background: rgba(255, 65, 39, 0.1);
  padding: 2px 9px;
  border-radius: 6px;
}
.status-pending {
  background: rgba(85, 63, 251, 0.1);
  color: #3e7c17;
  padding: 2px 9px;
  border-radius: 6px;
}
.date-section {
  font-size: 12px;
  color: #8892a2;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  cursor: pointer;
}
.date-selection {
  padding: 6px;
  border-radius: 6px;
  background: white;
  margin: 0 5px !important;
  display: flex;
  align-items: center;
}
.date-selection:hover {
  background: rgba(85, 63, 251, 0.1);
  color: #3e7c17;
}
.date-selection svg {
  font-size: 18px;
  margin: 0 10px;
}
.date-selection-active {
  background: rgba(85, 63, 251, 0.1);
  color: #3e7c17;
}
/* modal*/
.modal-invoice-main-container {
  position: fixed;
  z-index: 99999999;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-order-container {
  background: #fff;
  height: 80%;
  width: 100%;
  max-width: 750px;
  border-radius: 6px;
  height: 90%;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: scroll;
  position: relative;
}
.modal-order-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-order-header .modal-order-invoice,
.modal-order-header .modal-get-icons-container,
.modal-order-header .modal-close-container {
  flex: calc(100% / 3);
  width: calc(100% / 3);
}
.modal-order-header .modal-get-icons-container {
  display: flex;
  justify-content: center;
}
.modal-order-header .modal-close-container {
  display: flex;
  justify-content: flex-end;
}
.modal-order-header .modal-close-container img {
  cursor: pointer;
}
.modal-order-header .modal-order-invoice span {
  font-size: 12px;
  line-height: 15px;
  color: #8898aa;
}
.modal-order-header .modal-order-invoice p {
  font-weight: bold;
  color: #1c345d;
  word-wrap: break-word;
}
.modal-get-icons-container .modal-get-icon {
  border: 1px solid #e3e7ed;
  border-radius: 6px;
}
.modal-get-icons-container .modal-get-icon img {
  padding: 8px 20px;
  cursor: pointer;
}
.modal-get-icons-container .modal-get-icon img:nth-child(1) {
  border-right: 1px solid #e3e7ed;
}
.modal-invoice-sub-header {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 10px;
}
.modal-invoice-sub-header .modal-invoice-date {
  display: flex;
  flex-direction: column;
}
.modal-invoice-sub-header .modal-invoice-date span:nth-child(1) {
  font-size: 12px;
  line-height: 15px;
  color: #8898aa;
}
.modal-invoice-sub-header .modal-invoice-date span:nth-child(2) {
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  color: #1c345d;
}
.modal-invoice-address {
  color: #8892a2;
  display: flex;
  justify-content: space-between;
}
.modal-invoice-address .modal-invoice-sub-address {
  width: 180px;
  font-size: 14px;
}
.modal-invoice-table {
  margin-top: 30px;
}
.modal-invoice-table .modal-invoice-header {
  display: flex;
  color: #8892a2;
  font-size: 12px;
  height: 40px;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
}
.modal-invoice-table .modal-invoice-header .modal-table-header-item {
  width: calc(100% - 360px);
}
.modal-invoice-table .modal-invoice-header .modal-table-header-other {
  width: 120px;
}
.modal-invoice-table
  .modal-invoice-header
  .modal-table-header-other:last-child {
  text-align: right;
}
.modal-invoice-row {
  background: #f4f5f7;
  border-radius: 6px;
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 14px;
  color: #040a1d;
  margin: 1px 0;
  padding: 0 15px;
  box-sizing: border-box;
}
.modal-invoice-row .modal-table-row-item {
  width: calc(100% - 360px);
  display: flex;
  align-items: center;
}
.modal-invoice-row .modal-table-row-name {
  padding: 0 20px;
}
.modal-invoice-row .modal-table-row-qty {
  width: 120px;
}
.modal-invoice-row .modal-table-row-qty:last-child {
  text-align: right;
}
.modal-table-row-item .modal-table-row-no span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #e3e7ed;
}
.modal-invoice-price-details {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.modal-invoice-top-calc {
  font-size: 12px;
}
.modal-invoice-price-details .modal-invoice-price-calc {
  width: 360px;
}
.modal-invoice-top-calc .modal-invoice-calc-list {
  display: flex;
  padding: 0 15px;
}
.modal-invoice-top-calc .modal-invoice-calc-list span {
  display: block;
  text-align: right;
}
.modal-invoice-top-calc .modal-invoice-calc-list span:nth-child(1) {
  width: 30%;
  color: #8892a2;
}
.modal-invoice-top-calc .modal-invoice-calc-list span:nth-child(2) {
  width: 70%;
  color: #040a1d;
  font-weight: 500;
}
.modal-invoice-calc-result span {
  display: block;
  text-align: right;
}
.modal-invoice-calc-result {
  display: flex;
  height: 50px;
  border-radius: 6px;
  align-items: center;
  padding: 0 15px;
  margin: 10px 0;
}
.modal-invoice-calc-result span:nth-child(1) {
  width: 30%;
  font-size: 12px;
  font-weight: 500;
}
.modal-invoice-calc-result span:nth-child(2) {
  width: 70%;
  font-size: 20px;
  font-weight: bold;
}
.modal-invoice-completed {
  color: rgba(56, 201, 133, 1);
  background: rgba(56, 201, 133, 0.1);
}
.modal-invoice-cancelled {
  color: rgba(255, 65, 39, 1);
  background: rgba(255, 65, 39, 0.1);
}
.modal-invoice-pending {
  background: rgba(85, 63, 251, 0.1);
  color: #3e7c17;
}
.modal-invoice-status {
  display: flex;
  justify-content: flex-end;
}
.modal-invoice-status span {
  max-width: fit-content;
  font-size: 12px;
  padding: 1px 15px;
  border-radius: 6px;
}
.order-search-tip {
  height: 180px;
  border-radius: 6px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 25%;
}
.search-tip-head {
  text-transform: uppercase;
  color: #8892a2;
  font-size: 13px;
}
.search-tip-head {
  text-transform: uppercase;
  color: #8892a2;
  font-size: 13px;
}
.search-tip-body {
  color: #8892a2;
  font-size: 16px;
}
.search-tip-body {
  color: #8892a2;
  font-size: 16px;
}
.search-tip-bold {
  color: #040a1d;
  font-weight: 500;
}
.product-list {
  position: relative;
}
.order-noresult-container {
  height: 180px;
  border-radius: 6px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 25%;
  position: absolute;
  top: 0;
  left: 0;
}
.search-noresult-head {
  font-size: 18px;
  font-weight: 700;
  color: #3d4356;
}
.search-noresult-body {
  font-size: 14px;
  font-weight: bold;
  color: #8892a2;
  text-align: center;
}
.export-order-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.o-check-order-cont {
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #e9ecef;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 1px 3px rgba(50, 50, 93, 0.15);
  margin: 0 15px;
  cursor: pointer;
}
.checked-order {
  background: #38c985;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 3px;
  margin: 0 15px;
  cursor: pointer;
}
.export-order-box {
  background: #f4f5f7;
  width: 500px;
  border-radius: 4px;
  position: relative;
  padding: 15px 0;
}
.export-order-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  border-bottom: 1px solid #e3e7ed;
}
.export-order-header-container h2 {
  font-size: 20px;
  line-height: 25px;
  color: #040a1d;
  font-weight: 900;
}
.export-order-header-container span {
  position: absolute;
  right: 20px;
  top: 5px;
  height: 25px;
}
.export-order-header-container span img {
  height: 12px;
  margin: 6.5px 0;
  cursor: pointer;
}
.export-order-option-container {
  padding: 20px;
  box-sizing: border-box;
}
.export-order-btn-container {
  display: flex;
  justify-content: flex-end;
}
.export-order-btn-container button:nth-child(1) {
  border: 1px solid #8892a2;
  background-color: #fff;
  color: #3d4356;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px 30px;
  margin: 0 10px;
}
.export-order-btn-container button:nth-child(2) {
  border: none;
  background: #3e7c17;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px 30px;
  margin-left: 10px;
  margin-right: 20px;
}
.export-order-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 25px;
}
.export-order-option img {
  margin: 0 10px;
}
.export-order-date-section {
  background: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  color: #8892a2;
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}
.export-order-date-section svg {
  font-size: 20px;
  margin: 0 5px;
}
.export-order-date-section span {
  width: max-content;
  height: 100%;
  position: relative;
}
.export-order-date-section span input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.export-order-date-section span input::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
